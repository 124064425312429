export const CONNECTION_GAMES = [
  [
    {
      category: "Scranton mangers",
      words: ['Michael', 'Dwight', 'Jim', 'Andy'],
      difficulty: 1,
    },
    {
      category: "Characters in the anex",
      words: ['Toby', 'Ryan', 'Gabe', 'Kelly'],
      difficulty: 4,
    },
    {
      category:"Mangers of other branches",
      words: ['Karen', 'Josh', 'Craig', 'Dan'],
      difficulty: 3,
    },

    {
      category: "Women Michael dated",
      words: ['Carol', 'Jan', 'Concierge Marie', 'Helene'],
      difficulty: 2,
    },
  ]
];
